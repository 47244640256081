import { ApolloProvider, createGraphqlClient } from "@ignite-analytics/graphql-utilities";
import { getLocale } from "@ignite-analytics/locale";
import { getDesignTokens, responsiveFontSizes } from "@ignite-analytics/theme";
import { createTheme, CssBaseline, ThemeProvider as MuiThemeProvider } from "@mui/material";
import { createBrowserHistory } from "history";
import React from "react";
import { Route, Router } from "react-router-dom";
import ApplicationPage from "@/containers";
import FatalErrorBoundary from "@/components/FatalErrorBoundary";
import { BasePathProvider } from "@/contexts/BasePathContext";
import { IntlContext } from "@/contexts/IntlContext";
import { DataTableContext } from "@/generated/DataTableEntity";
import { MicroAppMountValues } from "./types";

const uri = process.env.REACT_APP_GRAPHQL_ROUTER_URL as string;
const graphqlClient = createGraphqlClient(uri);

/* Data tables MicroApp */
const App: React.FC<MicroAppMountValues> = ({
    history = createBrowserHistory(),
    locale = getLocale(),
    theme = "ignite-riddle",
    path,
}) => (
    <IntlContext locale={locale}>
        <FatalErrorBoundary>
            <Router history={history}>
                <CssBaseline />
                <ApolloProvider client={graphqlClient}>
                    <BasePathProvider path={path ?? "/data/repositories/"}>
                        <Route path={`${path ?? "/"}`}>
                            <MuiThemeProvider theme={responsiveFontSizes(createTheme(getDesignTokens(theme, "light")))}>
                                <DataTableContext>
                                    <ApplicationPage />
                                </DataTableContext>
                            </MuiThemeProvider>
                        </Route>
                    </BasePathProvider>
                </ApolloProvider>
            </Router>
        </FatalErrorBoundary>
    </IntlContext>
);

export default App;
