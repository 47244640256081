import React, { useState } from "react";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { LinearProgress, Stack, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { FileDropZone } from "@/components/FileDropZone";
import { getContentTypeFromFile } from "@/components/ImportConfiguration/CreateImportConfiguationForm/helpers";
import { UploadFileResponse } from "@/components/ImportConfiguration/services";
import { testIdPrefix } from "@/containers/DataRepositoryPage/testIdPrefix";
import { ValidateFileData, useDataManagementProcessEventListener } from "@/contexts/DataManagementProcessContext";
import { useDataRepositoryContext } from "@/contexts/DataRepositoryContext";
import { fm } from "@/contexts/IntlContext";
import {
    useCreateImportConfigurationMutation,
    useParseFileSamplesMutation,
    useUpdateImportConfigurationMutation,
    useValidateFileMutation,
} from "@/generated/client";
import useFileManager from "@/managers/useFileManager";
import * as globalMessages from "@/messages";
import { MAPPING_STEP, useUploadPageContext } from "../../context";
import { BottomButtonRow } from "../BottomButtonRow";
import ImportConfigurationDetail from "../ImportConfigurationDetail";
import { getCreateInput } from "./helpers";
import messages from "./messages";

interface Props {
    onClose: () => void;
}
const FileImportConfiguration: React.FC<Props> = ({ onClose }) => {
    const {
        uploadState: { fileToUpload, selectedImportConfiguration },
        dispatch,
    } = useUploadPageContext();
    const { selectedDataRepository } = useDataRepositoryContext();
    const [parseFileSamples] = useParseFileSamplesMutation();
    const [uploadProgress, setProgress] = useState<number>();
    const [loading, setLoading] = useState(false);
    const fileManager = useFileManager();
    const [createImportConfiguration] = useCreateImportConfigurationMutation();
    const [updateImportConfiguration] = useUpdateImportConfigurationMutation();
    const [validateFile] = useValidateFileMutation();
    const newValidateFileFlag = useFeatureToggle("new-file-parsing");
    const handleError = (err: Error) => {
        setLoading(false);
        setProgress(0);
        dispatch({
            type: "SET_WARNING",
            warning: { level: "ERROR", msg: err.message },
        });
    };
    const handleValidationComplete = (uploadResponse: UploadFileResponse) => {
        setProgress(100);
        setLoading(false);
        dispatch({ type: "SET_UPLOAD_RESPONSE", uploadResponse });
        dispatch({ type: "PROGRESS", progress: MAPPING_STEP });
    };
    useDataManagementProcessEventListener("ValidateFile", (e) => {
        const data: ValidateFileData | null = (e.data as ValidateFileData) || null;
        if (e.type === "PROCESS_FINISHED") {
            if (data) {
                handleValidationComplete({ fileId: `${e.id}`, fields: data.fields });
                data.warnings.length &&
                    dispatch({ type: "SET_WARNING", warning: { level: "WARNING", msg: data.warnings.join(", ") } });
            }
        }
        if (e.type === "FAILED") {
            setLoading(false);
            setProgress(0);
            if (!data) {
                dispatch({ type: "SET_WARNING", warning: { level: "ERROR", msg: "Something went wrong." } });
                return;
            }
            if (data.errors.length)
                dispatch({ type: "SET_WARNING", warning: { level: "ERROR", msg: data.errors.join(", ") } });
            if (data.warnings.length)
                dispatch({ type: "SET_WARNING", warning: { level: "WARNING", msg: data.warnings.join(", ") } });
            dispatch({ type: "SET_WARNING", warning: { level: "ERROR", msg: "Something went wrong" } });
        }
    });
    const handleNext = async () => {
        if (!fileToUpload || !selectedImportConfiguration) return;
        setLoading(true);
        dispatch({ type: "SET_WARNING", warning: undefined });
        const fileInformation = await fileManager.uploadFile(fileToUpload, (progress: number) =>
            setProgress(Math.round(progress * 0.9))
        );
        if (fileInformation.error) {
            handleError(fileInformation.error);
            return;
        }
        if (newValidateFileFlag) {
            await validateFile({
                input: {
                    fileId: `${fileInformation.data.id}`,
                    importConfigurationId: selectedImportConfiguration.id,
                },
            });
            return;
        }
        if (fileInformation.data) {
            try {
                const { data: parseResult } = await parseFileSamples({
                    input: {
                        fileId: `${fileInformation.data.id}`,
                        importConfigurationId: selectedImportConfiguration.id,
                    },
                });
                if (!parseResult) {
                    setProgress(0);
                    return;
                }
                if (parseResult.parseFileSamples.warnings.length > 0) {
                    dispatch({
                        type: "SET_WARNING",
                        warning: { level: "WARNING", msg: parseResult.parseFileSamples.warnings.join(" ") },
                    });
                }
                if (parseResult.parseFileSamples.errors.length > 0) {
                    handleError(new Error(parseResult.parseFileSamples.errors.join(" ")));
                    return;
                }
                handleValidationComplete(parseResult.parseFileSamples);
            } catch (error) {
                Sentry.captureException(error, {
                    tags: {
                        app: "data-repositories-app",
                        message: error instanceof Error ? error.message : "Unhandled error during file upload",
                    },
                });
                handleError(
                    new Error(
                        `${fm(messages.fileUploadError).toString()} ${
                            fileInformation.data.name.endsWith("xlsx") ? fm(messages.xlsxTipUploadTip).toString() : ""
                        }`
                    )
                );
            }
        }
    };
    const handleBack = () => {
        dispatch({ type: "RESET" });
        onClose();
    };

    const handleFileChange = async (file?: File) => {
        dispatch({ type: "SET_FILE_TO_UPLOAD", file });
        setProgress(0);
        if (!file) {
            dispatch({ type: "RESET" });
            return;
        }
        if (selectedImportConfiguration) {
            if (selectedImportConfiguration.contentType === getContentTypeFromFile(file)) return;
            await updateImportConfiguration({
                input: {
                    id: selectedImportConfiguration.id,
                    csvConfiguration: {
                        delimiter: selectedImportConfiguration.csvConfiguration?.delimiter,
                        encoding: selectedImportConfiguration.csvConfiguration?.encoding,
                        quoteCharacter: selectedImportConfiguration.csvConfiguration?.quoteCharacter,
                        escapeCharacter: selectedImportConfiguration.csvConfiguration?.escapeCharacter,
                    },
                    xlsxConfiguration: {
                        headerRowIndex: selectedImportConfiguration.xlsxConfiguration?.headerRowIndex,
                        dataStartIndex: selectedImportConfiguration.xlsxConfiguration?.dataStartIndex,
                        numberOfEndLinesToDrop: selectedImportConfiguration.xlsxConfiguration?.numberOfEndLinesToDrop,
                    },
                    contentType: getContentTypeFromFile(file),
                },
            });
            return;
        }
        const createInput = getCreateInput(selectedDataRepository, file);
        const createImportConfigRes = await createImportConfiguration({ input: createInput });
        dispatch({
            type: "SELECTED_IMPORT_CONFIGURATION",
            importConfiguration: createImportConfigRes.data?.createImportConfiguration.importConfiguration,
        });
    };
    const getLabel = () => {
        if (!uploadProgress) return null;
        if (uploadProgress === 100) return `${fm(messages.finished)} ${fileToUpload?.name}`;
        if (uploadProgress >= 90) return `${fm(messages.parsing)} ${fileToUpload?.name}`;
        return `${fm(messages.uploading)} ${fileToUpload?.name}`;
    };
    return (
        <Stack gap={1}>
            <FileDropZone
                initialFile={fileToUpload}
                onFileChange={handleFileChange}
                fileTypes={[".xlsx", ".XLSX", ".csv", ".CSV"]}
            />
            <ImportConfigurationDetail />
            {uploadProgress ? (
                <Stack>
                    <Typography variant="subtitle1">{getLabel()}</Typography>
                    <LinearProgress variant="determinate" value={uploadProgress} />
                </Stack>
            ) : null}
            <BottomButtonRow
                backButtonMessage={fm(globalMessages.default.cancel).toString()}
                nextButtonMessage={fm(globalMessages.default.next).toString()}
                loading={loading}
                handleBack={handleBack}
                handleNext={() => {
                    handleNext().catch((e) => {
                        Sentry.captureException(e, { tags: { app: "data-repositories-app" } });
                    });
                }}
                nextButtonDisabled={!selectedImportConfiguration || !fileToUpload}
                dataTestId={`${testIdPrefix}-parse-file-samples`}
            />
        </Stack>
    );
};

export default FileImportConfiguration;
